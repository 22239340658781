import Box from '@material-ui/core/Box'
import { ISectionComponentProps } from 'components/Sections/utils';
import { Headline3 } from '@/shared/components/Typography';
import { useIntl } from 'react-intl';
import { sectionMessages } from 'components/Sections/messages';
import Tag from '@/shared/components/Tag/Tag';
import EmptySocials from './EmptySocials';
import { IconSize } from '../types';

const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`
const networks = ['facebook', 'youtube', 'instagram', 'twitter', 'snapchat']
const icons = {
    facebook: `${iconPath}/websites/28px/Facebook.svg`,
    youtube: `${iconPath}/websites/28px/Youtube.svg`,
    instagram: `${iconPath}/websites/28px/Instagram.svg`,
    twitter: `${iconPath}/websites/28px/Twitter.svg`,
    snapchat: `${iconPath}/websites/28px/Snapchat.svg`
}

export default function SocialNetworkDefault(props: ISectionComponentProps) {
    const { section } = props
    const intl = useIntl()
    const filteredSocials = networks.filter(network => section?.content?.[network])
    const isLarge = section?.customize?.format?.icon_size === IconSize.LARGE

    return (
        <Box>
            <Box justifyContent='center' textAlign='center' pb={4}>
                <Headline3>{section.name || intl.formatMessage(sectionMessages['social.social_networks'])}</Headline3>
            </Box>
            <Box pb={4} display='flex' alignItems='center' justifyContent='center'>
                <Box display='flex' justifyContent='space-between'>
                    {filteredSocials && filteredSocials
                        .map(network => renderNetwork({ key: network, icon: icons[network], url: section?.content[network], isLarge }))}
                    {filteredSocials && filteredSocials.length === 0 && (
                        <EmptySocials count={networks.length} />
                    )}
                </Box>
            </Box>
        </Box>
    )

    function renderNetwork({ icon, url, key, isLarge }: { url: string, icon: string, key: string, isLarge: boolean }) {
        return (
            <a key={key} href={url} target='_blank' rel='noreferrer'>
                <Box mr={2}>
                    <Tag imgSrc={icon} size={isLarge ? 'large' : 'medium'} />
                </Box>
            </a>
        )
    }
}