import Tag from '@/shared/components/Tag/Tag';
import { range } from '@/shared/utils/range';
import Box from '@material-ui/core/Box'
import { IEmptySocialsProps } from '../types';

export default function EmptySocials(props: IEmptySocialsProps) {
    return (
        <>
            {range(props.count).map(e => (
                <Box key={e} mr={2}>
                    <Tag />
                </Box>
            ))}
        </>
    )
}