
export interface ISocialNetworkSectionContent {
    facebook?: string
    twitter?: string
    instagram?: string
    youtube?: string
    snapchat?: string
}


export interface IEmptySocialsProps {
    count: number
}

export enum IconSize {
    SMALL = 'small',
    LARGE = 'large'
}

